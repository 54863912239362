import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Tabletop from 'tabletop';
var publicSpreadsheetUrl = 'https://docs.google.com/spreadsheets/d/1Ug1WzgoT1R32E8Y1yYdanYfab3Yz2JIWrMLBzC0WmV4/edit?usp=sharing';

var tutors = [];
var dataItems;

function init() {
    Tabletop.init( { key: publicSpreadsheetUrl,
                     callback: showInfo,
                     simpleSheet: true } )
  }

window.addEventListener('DOMContentLoaded', init)

function showInfo(data, tabletop) {
    var nombres = [];
    for (var i = 0; i < data.length; i++) {
        //Getting data
        var dctLanguages = data[i.toString()];
        tutors.push(dctLanguages)
        //console.log(typeof dctLanguages);
        //console.log(dctLanguages);
        for (var key in dctLanguages)
          {
            if (key === 'Name')
            {
                nombres.push(dctLanguages[key]);
                var name = dctLanguages[key];
                //console.log(typeof name);
            }
            if (key === 'Grade') 
            {
                var grade = dctLanguages[key];
            }
            if (key === 'Connect')
            {
                var connect = dctLanguages[key];
            }
            if (key === 'Bio')
            {
                var bio = dctLanguages[key];
            }
              if (key === 'Personality')
            {
                var person = dctLanguages[key];
            }
              
            if (key === 'Image') 
                {
                    var imageURL = dctLanguages[key];
                }
          }
        
        var tutor = {
          name: name,
            grade: grade,
            connect: connect,
            bio: bio,
            person: person,
            imageURL: imageURL
        };
        tutors.push(tutor);
        
    };
    //console.log(nombres);
    
    ReactDOM.render(<App names={tutors}/>, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
