import React from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css'
import './App.css';
import 'bootstrap';
import Tabletop from 'tabletop';
import 'nodemailer';


function onClickButton() {
    /* var nodemailer = require('nodemailer');

var transporter = nodemailer.createTransport({
  service: 'gmail',
  auth: {
    user: 'chris.pondoc@gmail.com',
    pass: 'Ch3r1f3r1022!'
  }
});

var mailOptions = {
  from: 'chris.pondoc@gmail.com',
  to: 'chris.pondoc@comcast.net',
  subject: 'Sending Email using Node.js',
  text: 'That was easy!'
};

transporter.sendMail(mailOptions, function(error, info){
  if (error) {
    console.log(error);
  } else {
    console.log('Email sent: ' + info.response);
  }
});  */
}

function clickMe(event, name) {
    window.location.href = "./contact.html";
}

function App(props) {
    var woah = props.names;
        console.log(woah.length);
    var names = [];
    for (var i = 0; i <= woah.length; i += 2 )
    {
        names.push(woah[i])
    }
    names.pop()
    console.log(names[3].Name)
    //console.log(names[0]);
    const tutorItems = names.map((name) =>
                                 <a href="#" data-toggle="modal" data-target="#woahModal">
            <li class="list-group-item" onClick={(e) => {
     clickMe(e, name.Name)
}}>
                                   
            <div class="row">
                <div class="col-4">
                    <h1 class="toTheLeft"><strong>{name.Name}</strong></h1>
                    <h2>⭐⭐⭐⭐⭐</h2>
                    <img src={name.Image} />
                </div>
                <div class="col-8">
                                 <br />
                                 <br />
                                 <br />
                                  <br />
                                  <br />
                <h3><strong>Grade:</strong> {name.Grade}</h3>
                                 <h3><strong>Bio:</strong> {name.Bio}</h3>
                                 <h3><strong>Connect:</strong> {name.Connect}</h3>
                                 <h3><strong>Personality:</strong> {name.Personality}</h3>
                </div>
            </div>
                                 <br />
                                 
                                 
                                 </li>
</a>
    );
  return (
    <div className="App">
      <br />
      <header>
        <h1 id='title'><strong>📚 Peerio 📚</strong></h1>
        <h3>Discover, Choose, and Contact Peer Tutors at your school.</h3>
        <br />
      <div class="container">
      <div class="row">
    <div class="col-sm">
      <a class="menu" href="index.html"><h4><strong>👨‍🏫 Home </strong></h4></a>
    </div>
    <div class="col-sm">
      <a class="menu" href="contact.html"><h4><strong>📨 Contact </strong></h4></a>
    </div>
    <div class="col-sm">
      <a class="menu" href="about.html"><h4><strong>🤔 About </strong></h4></a>
    </div>
      </div>
      </div>
      <br />
      <div id="features" class="container">
        <h4><strong>School: </strong><select>
    <option value="FHS"> Frederick High School</option>
</select><strong>  Subject: </strong><select>
    <option value="math"> Math</option>
</select></h4>
      </div>
      </header>
      <div class="container">
        <br></br>
        <ul class="list-group">{tutorItems}
        </ul>
      </div>
      <br />
      <footer class="container">
    <p>&middot; Developed with ❤️ by Chris Pondoc &middot;</p>
  </footer>
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Contact Tutor</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
  <div class="form-group">
    <label for="inputName">Name</label>
    <input type="text" class="form-control" id="inputName" aria-describedby="nameHelp" placeholder="Enter Full Name" />
    <small id="nameHelp" class="form-text text-muted">We'll never share your name with anyone except the tutor you want to contact.</small>
  </div>
  <div class="form-group">
    <label id="inputEmail" for="inputEmail">Email</label>
    <input type="email" class="form-control" id="inputEmail" placeholder="Email" />
  </div>
  <div class="form-group">
  <label for="comment">Comments/Requests:</label>
  <textarea class="form-control" rows="5" id="comment"></textarea>
</div> 
  <button type="submit" onClick={onClickButton()} class="btn btn-primary">Submit</button>
</form>
      </div>
    </div>
  </div>
</div></div>
  ); 
}

export default App;